import { useEffect, useRef } from 'react';

const useShuffleText = (text: string) => {
    const elementRef = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
        if (!elementRef.current || text === "") return;

        const element = elementRef.current;
        let changeCountMax = text.length;
        let changeCount = new Array(text.length).fill(0);
        let index = 0;
        const interval = 20;
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ！？".split('');

        const shuffleText = (text: string) => {
            let shuffledText = '';
            for (let i = 0; i < text.length; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                shuffledText += characters[randomIndex];
            }
            return shuffledText;
        }

        const startAnimation = () => {
            const animationInterval = setInterval(() => {
                let changeText = "";
                for (let i = 0; i <= index; i++) {
                    if (changeCount[i] >= changeCountMax) {
                        changeText += text[i];
                    } else {
                        changeText += shuffleText(text.slice(i, i + 1));
                    }
                    element.textContent = changeText;
                    if (i < text.length) changeCount[i]++;
                }
                index++;
                if (changeCount[changeCountMax - 1] >= changeCountMax) {
                    clearInterval(animationInterval);
                    element.textContent = text;
                }
            }, interval);
        }

        startAnimation();
    }, [text]);

    return elementRef;
}

export default useShuffleText;
