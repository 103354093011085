import React from 'react';
import ShuffleTextLabel from './ShuffleTextLabel';

interface WorksOnlyCheckboxProps {
    selectedGenre: string;
    onChange: (genre: string) => void;
    masonryLoaded: boolean;
}

const genres = ["Work/Event", "CreativeCoding", "3DPrinter"];

const WorksOnlyCheckbox: React.FC<WorksOnlyCheckboxProps> = ({ selectedGenre, onChange, masonryLoaded }) => {
    return (
        <div className="works-only-checkbox">
            {genres.map((genre) => (
                <label key={genre}>
                    <input
                        type="radio"
                        name="genre"
                        value={genre}
                        checked={selectedGenre === genre}
                        onChange={() => onChange(genre)}
                    />
                    <ShuffleTextLabel text={genre} startAnimation={masonryLoaded} />
                </label>
            ))}
            <label>
                <input
                    type="radio"
                    name="genre"
                    value=""
                    checked={selectedGenre === ""}
                    onChange={() => onChange("")}
                />
                <ShuffleTextLabel text="All" startAnimation={masonryLoaded} />
            </label>
        </div>
    );
}

export default WorksOnlyCheckbox;
