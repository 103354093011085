// src/routes/AppRoutes.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from '../containers/Home';
import About from '../containers/About';
import Contact from '../containers/Contact';
import NameButton from '../components/NameButton';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const dummyData = {
  artistName: "Masato Usuba / USB",
  profileImage: "/images/01.webp",
  description: "",
  snsLinks: [
    { icon: "path_to_twitter_icon.png", link: "https://x.com/usbhatyu" },
    { icon: "path_to_facebook_icon.png", link: "https://facebook.com/" },
    { icon: "path_to_instagram_icon.png", link: "https://www.instagram.com/usb_create/" },
    { icon: "path_to_linkedin_icon.png", link: "https://linkedin.com/" }
  ]
};

const AppRoutes: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About {...dummyData} />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default AppRoutes;
