import React from 'react';
import useShuffleText from '../utils/useShuffleText';

interface ShuffleTextLabelProps {
    text: string;
    startAnimation: boolean;
}

const ShuffleTextLabel: React.FC<ShuffleTextLabelProps> = ({ text, startAnimation }) => {
    const textRef = useShuffleText(startAnimation ? text : "");

    return (
        <span ref={textRef}></span>
    );
}

export default ShuffleTextLabel;
