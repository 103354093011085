import React, { useState, useEffect } from 'react';
import ProfileModal from './ProfileModal';
import useShuffleText from '../utils/useShuffleText';
import './Navbar.css';

const dummyData = {
    artistName: "Masato Usuba / USB",
    profileImage: "/images/profile.webp",
    description: "USB is a visual artist and programmer based in Tokyo. He has been involved in developing augmented reality (AR) and interactive content for an advertising production company. His expertise spans 3DCG, web development, electronics, gaming, video production, and music. His visual works are generative art pieces using TouchDesigner and GLSL, offering figurative representations of animals and plants.\n----------------\nUSBは東京を拠点に活動するビジュアルアーティスト兼プログラマー。広告制作会社でAR（拡張現実）およびインタラクティブコンテンツの開発に携わってきました。作業範囲は3DCG、ウェブ、電子工作、ゲーム、映像、音楽に及びます。ビジュアル作品はTouchDesignerとGLSLを使用したジェネラティブアートで、動物や植物などの形象的な表現を提供しています。",
    snsLinks: [
        { icon: "path_to_twitter_icon.png", link: "https://x.com/usbhatyu" },
        { icon: "path_to_instagram_icon.png", link: "https://www.instagram.com/usb_create/" },
    ]
};

interface NavbarProps {
    masonryLoaded: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ masonryLoaded }) => {
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
    const [startAnimation, setStartAnimation] = useState(false);

    useEffect(() => {
        if (masonryLoaded) {
            setStartAnimation(true);
        }
    }, [masonryLoaded]);

    const handleOpenProfileModal = () => {
        setIsProfileModalOpen(true);
    };

    const handleCloseProfileModal = () => {
        setIsProfileModalOpen(false);
    };

    const navbarRef = useShuffleText(startAnimation ? "ABOUT" : "");

    return (
        <nav className="navbar">
            <button className="navbar-close-btn" onClick={handleOpenProfileModal}>
                <p ref={navbarRef} className="navbar-text"></p>
            </button>
            {isProfileModalOpen && <ProfileModal {...dummyData} onClose={handleCloseProfileModal} />}
        </nav>
    );
}

export default Navbar;
