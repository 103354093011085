import React, { useState, useEffect } from 'react';
import './Modal.css';
import { FaInstagram, FaYoutube, FaLink } from 'react-icons/fa';

interface ModalProps {
    work: {
        id: number;
        title: string;
        description: string;
        imageUrls: string[];
        url?: string;
    };
    onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ work, onClose }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        setIsVisible(true);

        return () => {
            setIsVisible(false);
        };
    }, []);

    useEffect(() => {
        if (!isVisible) {
            const timer = setTimeout(() => {
                onClose();
            }, 300);

            return () => clearTimeout(timer);
        }
    }, [isVisible, onClose]);

    const handleClose = () => {
        setIsVisible(false);
    };

    const handlePrevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : work.imageUrls.length - 1));
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex < work.imageUrls.length - 1 ? prevIndex + 1 : 0));
    };

    const isInstagramUrl = work.url && work.url.includes("instagram.com");
    const isYoutubeUrl = work.url && work.url.includes("youtube.com");

    return (
        <div className={`modal-overlay ${isVisible ? 'modal-overlay-visible' : 'modal-overlay-hidden'}`} onClick={handleClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="modal-close-btn" onClick={handleClose}>×</button>
                <p>{work.title}</p>
                <div className="image-container">
                    {work.imageUrls.length > 1 && (
                        <button className="nav-button left" onClick={handlePrevImage}>‹</button>
                    )}
                    <img src={work.imageUrls[currentImageIndex]} alt={work.title} />
                    {work.imageUrls.length > 1 && (
                        <button className="nav-button right" onClick={handleNextImage}>›</button>
                    )}
                </div>
                {work.imageUrls.length > 1 && (
                    <div className="indicators">
                        {work.imageUrls.map((_, index) => (
                            <div key={index} className={`indicator ${index === currentImageIndex ? 'active' : ''}`} />
                        ))}
                    </div>
                )}
                <p>{work.description}</p>
                {work.url && (
                    <a
                        href={work.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="modal-btn"
                    >
                        {isInstagramUrl ? <FaInstagram /> : isYoutubeUrl ? <FaYoutube /> : <FaLink />}
                    </a>
                )}
            </div>
        </div>
    );
}

export default Modal;
