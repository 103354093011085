import React, { useEffect, useState } from 'react';
import './CustomMasonry.css';

interface CustomMasonryProps {
    items: React.ReactElement[];
}

const CustomMasonry: React.FC<CustomMasonryProps> = ({ items }) => {
    const [columns, setColumns] = useState<React.ReactElement[][]>([]);

    useEffect(() => {
        const updateColumns = () => {
            const isMobile = window.innerWidth < 767;
            const columnCount = isMobile ? 2 : 3;

            const newColumns: React.ReactElement[][] = Array.from({ length: columnCount }, () => []);
            const columnHeights = Array(columnCount).fill(0);

            items.forEach(item => {
                const itemHeight = item.props.height || 1; // デフォルトの高さを設定
                const shortestColumnIndex = columnHeights.indexOf(Math.min(...columnHeights));
                newColumns[shortestColumnIndex].push(item);
                columnHeights[shortestColumnIndex] += itemHeight;
            });

            setColumns(newColumns);
        };

        updateColumns();
        window.addEventListener('resize', updateColumns);
        return () => window.removeEventListener('resize', updateColumns);
    }, [items]);

    return (
        <div className="masonry-grid">
            {columns.map((columnItems, columnIndex) => (
                <div key={columnIndex} className="masonry-column">
                    {columnItems}
                </div>
            ))}
        </div>
    );
};

export default CustomMasonry;
