import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import './Tile.css';

interface TileProps {
    id: number;
    title: string;
    description: string;
    imageUrls: string[];
    url: string;
}

const Tile: React.FC<TileProps> = ({ id, title, description, imageUrls, url }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        // 50msの遅延を追加してアニメーションを制御
        const timer = setTimeout(() => {
            setLoaded(true);
        }, 50);
        
        // クリーンアップ関数
        return () => clearTimeout(timer);
    }, []);

    const isInstagramUrl = url.includes("instagram.com");

    return (
        <div className={`tile-wrapper ${loaded ? 'tile-entered' : ''}`}>
            <div className="tile" onClick={() => setIsModalOpen(true)}>
                <div className="tile-image">
                    <img src={imageUrls[0]} alt={title} />
                </div>
            </div>
            {isModalOpen && (
                <Modal work={{ id, title, description, imageUrls, url }} onClose={() => setIsModalOpen(false)} />
            )}
        </div>
    );
}

export default Tile;
