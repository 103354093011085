import React, { useState, useEffect, useCallback } from 'react';
import Tile from '../components/Tile';
import WorksOnlyCheckbox from '../components/WorksOnlyCheckbox';
import CustomMasonry from '../components/CustomMasonry'; // Import custom Masonry component
import Navbar from '../components/Navbar';
import NameButton from '../components/NameButton';    
import './Home.css';

// ダミーデータ (後でAPIなどから取得する形に変更)
const works = [
    { id: 1, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/01.webp"], url: "https://www.instagram.com/p/C6q8_1Yy4D7/", genre: "CreativeCoding" },
    { id: 2, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/02.webp"], url: "https://www.instagram.com/p/C6hqjXHyrky/?img_index=1", genre: "CreativeCoding" },
    { id: 3, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/03.webp"], url: "https://www.instagram.com/p/C6eHNabyEl1/?img_index=1", genre: "CreativeCoding" },
    { id: 4, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/04.webp"], url: "https://www.instagram.com/p/C6fMTloSB3y/?img_index=1", genre: "CreativeCoding" },
    { id: 5, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/05.webp"], url: "https://www.instagram.com/p/C6bYBZ_ScAE/?img_index=1", genre: "CreativeCoding" },
    { id: 6, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/06.webp"], url: "https://www.instagram.com/p/C5TEII8y646/?img_index=1", genre: "CreativeCoding" },
    { id: 7, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/07-1.webp", "/images/creativecoding/07-2.webp", "/images/creativecoding/07-3.webp"], url: "https://www.instagram.com/p/C5OLArSy8hn/?img_index=1", genre: "CreativeCoding" },
    { id: 8, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/08.webp"], url: "https://www.instagram.com/p/CornBKkPUCd/?img_index=1", genre: "CreativeCoding" },
    { id: 9, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/09.webp"], url: "https://www.instagram.com/p/Cn-9DMzJaCf/?img_index=1", genre: "CreativeCoding" },
    { id: 10, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/10.webp"], url: "https://www.instagram.com/p/Cn4azwWJfcm/?img_index=1", genre: "CreativeCoding" },
    { id: 11, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/11.webp"], url: "https://www.instagram.com/p/CnzMQ1jJUzd/?img_index=1", genre: "CreativeCoding" },
    { id: 12, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/12.webp"], url: "https://www.instagram.com/p/CnzMGcWJ1el/?img_index=1", genre: "CreativeCoding" },
    { id: 13, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/13.webp"], url: "https://www.instagram.com/p/CnzMXO4pJFY/?img_index=1", genre: "CreativeCoding" },
    { id: 14, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/14.webp"], url: "https://www.instagram.com/p/CnmXMsUJWCo/?img_index=1", genre: "CreativeCoding" },
    { id: 15, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/15.webp"], url: "https://www.instagram.com/p/CIDE9ByhKhi/", genre: "CreativeCoding" },
    { id: 16, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/16.webp"], url: "https://www.instagram.com/p/CIDFXgRBsMZ/", genre: "CreativeCoding" },
    { id: 17, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/17.webp"], url: "https://www.instagram.com/p/CIDFPnUBaoa/", genre: "CreativeCoding" },
    { id: 18, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/18.webp"], url: "https://www.instagram.com/p/CJNS_9TBoiv/?img_index=1", genre: "CreativeCoding" },
    { id: 19, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/19.webp"], url: "https://www.instagram.com/p/COmpoR-hI1C/", genre: "CreativeCoding" },
    { id: 20, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/20.webp"], url: "https://www.instagram.com/p/COmqWvNBMDi/", genre: "CreativeCoding" },
    { id: 21, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/21.webp"], url: "https://www.instagram.com/p/CPQYTJ_hWgM/", genre: "CreativeCoding" },
    { id: 22, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/22.webp"], url: "https://www.instagram.com/p/CRy4mNThabk/", genre: "CreativeCoding" },
    { id: 23, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/23.webp"], url: "https://www.instagram.com/p/CR1Rs44oV7X/", genre: "CreativeCoding" },
    { id: 24, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/24.webp"], url: "https://www.instagram.com/p/CRy4JbPBhgc/", genre: "CreativeCoding" },
    { id: 25, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/25.webp"], url: "https://www.instagram.com/p/CnSvdi9BNYf/", genre: "CreativeCoding" },
    { id: 26, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/26.webp"], url: "https://www.instagram.com/p/CnSv_r-hLki/?img_index=1", genre: "CreativeCoding" },
    { id: 27, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/27.webp"], url: "https://www.instagram.com/p/CnZmhz5B3J9/?img_index=1", genre: "CreativeCoding" },
    { id: 28, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/28.webp"], url: "https://www.instagram.com/p/CnoqCZnpuWk/?img_index=1", genre: "CreativeCoding" },
    { id: 29, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/29.webp"], url: "https://www.instagram.com/p/CnpAbtEp20x/?img_index=1", genre: "CreativeCoding" },
    { id: 30, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/30.webp"], url: "https://www.instagram.com/p/Cn23tBIy0sO/?img_index=1", genre: "CreativeCoding" },
    { id: 31, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/31.webp"], url: "https://www.instagram.com/p/CokJZU8pdQq/?img_index=1", genre: "CreativeCoding" },
    { id: 32, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/32.webp"], url: "https://www.instagram.com/p/CpZY0B2uEYD/?img_index=1", genre: "CreativeCoding" },
    { id: 33, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/33.webp"], url: "https://www.instagram.com/p/CoVzGOiyshN/?img_index=1", genre: "CreativeCoding" },
    { id: 34, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/34.webp"], url: "https://www.instagram.com/p/Co41V58P30d/?img_index=1", genre: "CreativeCoding" },
    { id: 35, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/35.webp"], url: "https://www.instagram.com/p/CpftrLTPdIx/?img_index=1", genre: "CreativeCoding" },
    { id: 36, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/36.webp"], url: "https://www.instagram.com/p/CpaH2ItvMrp/?img_index=1", genre: "CreativeCoding" },
    { id: 37, title: "CreativeCoding", description: "/TouchDesigner/", imageUrls: ["/images/creativecoding/37.webp"], url: "https://www.instagram.com/p/CrcvQIhtjWl/", genre: "CreativeCoding" },
    { id: 38, title: "TissueCaseFrog", description: "/3DPrinter/", imageUrls: ["/images/printer/01.webp"], url: "", genre: "3DPrinter" },
    { id: 39, title: "AccessoryCaseFrog", description: "/3DPrinter/", imageUrls: ["/images/printer/02-1.webp", "/images/printer/02-2.webp"], url: "", genre: "3DPrinter" },
    { id: 40, title: "ClipJellyfish", description: "/3DPrinter/", imageUrls: ["/images/printer/03-1.webp", "/images/printer/03-2.webp"], url: "", genre: "3DPrinter" },
    { id: 41, title: "MagnetBlackGhostKnifefish", description: "/3DPrinter/", imageUrls: ["/images/printer/04.webp"], url: "", genre: "3DPrinter" },
    { id: 42, title: "NEORT CURATION FREE #2", description: "/Projection/Exhibition/", imageUrls: ["/images/projection/01-1.webp", "/images/projection/01-2.jpg", "/images/projection/01-3.jpg"], url: "", genre: "Work/Event" },
    { id: 43, title: "さざれ場 #001", description: "/AudioVisual/Performance/", imageUrls: ["/images/vj/01.webp"], url: "https://www.youtube.com/watch?v=uX0eoSEk1Xs", genre: "Work/Event" },
    { id: 44, title: "0 // 2023 Public Visuals Tokyo x TDSW", description: "/AudioVisual/Performance/", imageUrls: ["/images/vj/02-1.webp", "/images/vj/02-2.webp", "/images/vj/02-3.webp", "/images/vj/02-4.webp"], url: "https://www.youtube.com/watch?v=IvVEjjvTNmg", genre: "Work/Event" },
    { id: 45, title: "DOMMUNE Proof of X-Blockchain as a NewMedium forArt", description: "/AudioVisual/Performance/", imageUrls: ["/images/vj/03-1.webp", "/images/vj/03-2.webp"], url: "https://www.youtube.com/watch?v=L3QsGDohBTE", genre: "Work/Event" },
    { id: 46, title: "日刊タイポ MOJIMOZ SHIRITORI", description: "日刊タイポさんが出展した六本木なんでもデイで発表された作品である未確認字体生物MOJIMOZ。このMOJIMOZを使用したインスタフィルターを開発し展示を行いました。", imageUrls: ["/images/work/06-1.png"], url: "https://www.instagram.com/nikkantypo/", genre: "Work/Event" },
    // { id: 47, title: "タッチ de びせいぶつ狩り", description: "子供たちが微生物に興味を持ち楽しく学んでもらうため、“自身の手で身近な微生物を捕まえられる”というゲーム性のあるUX設計をしました。本コンテンツでは開発全行程を行いました。", imageUrls: ["/images/work/01-1.png", "/images/work/01-2.png", "/images/work/01-3.png", "/images/work/01-4.png"], url: "https://www.youtube.com/watch?v=_9a6Yl1z2vA", genre: "Work/Event" },
    // { id: 48, title: "すずめの戸締まり デジタルプロモーション", description: "キャンペーンサイトにおいては、劇中「ダイジン」が、各地で目撃されるシーンの動画を背景に用いることで、キャンペーンへの参加を通じて映画にも参加している気分が味わえるようにデザイン。写真投稿の際にも、「ダイジン」の特徴的な目を数パターンから選んで、写真に合成できるオリジナルARカメラを開発し、ユーザーが遊べる要素を残すことで、さまざまな投稿が生まれるよう工夫させていただきました。本案件ではAR開発を担当。", imageUrls: ["/images/work/02-1.jpg", "/images/work/02-2.jpg", "/images/work/02-3.jpg"], url: "https://www.taki.co.jp/works/promotion/suzumenotojimari_promotion/", genre: "Work/Event" },
    // { id: 49, title: "ルミネクリスマス デジタルコンテンツ", description: "ルミネ全館や館周辺・沿線のOOH、オウンドメディア、SNSなど、さまざまな場所で手がけたクリエイティブが掲出されました。「想いを伝えたい相手に贈る」ことを狙いとしていたデジタルコンテンツ「GIFT OF VOICE TREE」は、体験していただいた方の半数以上が、SNSでコンテンツをシェア。新しいクリスマスの形を多くの方に楽しんでいただきました。本案件ではデジタルコンテンツの開発を行いました。", imageUrls: ["/images/work/03-1.jpg", "/images/work/03-2.png", "/images/work/03-3.jpg", "/images/work/03-4.jpg"], url: "https://www.taki.co.jp/works/promotion/lumine2021_christmas/", genre: "Work/Event" },
    // { id: 50, title: "ピカピカドロだんご", description: "コロナ禍において、子どもが「正しい手洗い」を行えていない問題を解決するため、「30秒の手洗い」と「正しく菌を落とす手洗い」を楽しみながら習慣化するコンテンツを開発しました。本コンテンツではCG演出を担当。", imageUrls: ["/images/work/04-1.jpg", "/images/work/04-2.jpg", "/images/work/04-3.jpg", "/images/work/04-4.jpg", "/images/work/04-5.jpg"], url: "https://www.youtube.com/watch?v=uHD_cO5RVes", genre: "Work/Event" },
    // { id: 51, title: "人生の大切なことをゲームから学ぶ展", description: "人生の中で誰もが体験するであろう8つのテーマ「成長，勝利，仲間，困難，お金，挑戦，時間，考え方」をもとに制作した，8本の新作オリジナルゲームを展示し，すべて無料でプレイできる体験型の企画展。展示内2本のゲームを開発。", imageUrls: ["/images/work/05-1.jpg", "/images/work/05-2.jpg", "/images/work/05-3.jpg", "/images/work/05-4.png", "/images/work/05-5.png"], url: "https://gamekaramanabu-ten.com/", genre: "Work/Event" },
    { id: 52, title: "GrilledMeatSauceKeyRing", description: "/3DPrinter/", imageUrls: ["/images/printer/05.jpg"], url: "", genre: "3DPrinter" },
    { id: 53, title: "TissueKeyRing", description: "/3DPrinter/", imageUrls: ["/images/printer/06.jpg"], url: "", genre: "3DPrinter" },
];

type Work = {
    id: number;
    title: string;
    description: string;
    imageUrls: string[];
    url: string;
    genre: string;
    height?: number; // Assuming each work has a 'height' prop for simplicity
};

const Home: React.FC = () => {
    const getRandomWorks = useCallback((num: number, excludeIds: Set<number>): Work[] => {
        const selectedWorks: Work[] = [];
        const availableWorks = works.filter(work => !excludeIds.has(work.id));

        while (selectedWorks.length < num && availableWorks.length > 0) {
            const randomIndex = Math.floor(Math.random() * availableWorks.length);
            const work = availableWorks.splice(randomIndex, 1)[0];

            selectedWorks.push(work);
            excludeIds.add(work.id);
        }

        return selectedWorks;
    }, []);

    const generateNums = 20;

    const [displayedWorks, setDisplayedWorks] = useState<Work[]>(() => {
        const initialWorks = getRandomWorks(generateNums, new Set());
        return initialWorks;
    }); // 初めの15個をランダムに表示
    const [excludedIds, setExcludedIds] = useState<Set<number>>(new Set(displayedWorks.map(work => work.id)));
    const [selectedGenre, setSelectedGenre] = useState<string>("");
    const [key, setKey] = useState<number>(0); // 新しい状態を追加
    const [isLoaded, setIsLoaded] = useState<boolean>(true); // ページ読み込み完了状態
    const [masonryLoaded, setMasonryLoaded] = useState<boolean>(false); // Masonry表示完了状態

    const loadMoreWorks = useCallback(() => {
        let nextWorks = getRandomWorks(generateNums, new Set(excludedIds));

        // もし表示していない作品がなくなった場合、excludedIdsをリセット
        if (nextWorks.length < generateNums && excludedIds.size >= works.length) {
            setExcludedIds(new Set());
            nextWorks = [...nextWorks, ...getRandomWorks(generateNums - nextWorks.length, new Set())];
        }

        setDisplayedWorks(prev => [...prev, ...nextWorks]);
        setExcludedIds(prev => new Set([...prev, ...nextWorks.map(work => work.id)]));
    }, [excludedIds, getRandomWorks]);

    const handleScroll = useCallback(() => {
        if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 200) {
            loadMoreWorks();
        }
    }, [loadMoreWorks]);

    useEffect(() => {
        if (selectedGenre === "") {
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll, selectedGenre]);

    useEffect(() => {
        if (isLoaded) {
            setTimeout(() => {
                setMasonryLoaded(true);
            }, 1000); // 適切な遅延を設定
        }
    }, [isLoaded]);

    const handleGenreChange = (genre: string) => {
        setSelectedGenre(genre);
        setDisplayedWorks([]); // 既存の作品をすべて削除
        setExcludedIds(new Set()); // excludedIdsもリセット

        if (genre === "") {
            const newWorks = getRandomWorks(generateNums, new Set());
            setDisplayedWorks(newWorks);
            setExcludedIds(new Set(newWorks.map(work => work.id)));
        } else {
            const genreWorks = works.filter(work => work.genre === genre);
            setDisplayedWorks(genreWorks);
            setExcludedIds(new Set(genreWorks.map(work => work.id)));
        }

        // keyを変更してコンポーネントの再レンダリングを強制
        setKey(prevKey => prevKey + 1);
    };

    const filteredWorks = selectedGenre
        ? displayedWorks.filter(work => work.genre === selectedGenre)
        : displayedWorks;

    return (
        <div className="home-container">
            <NameButton masonryLoaded={masonryLoaded} />
            <Navbar masonryLoaded={masonryLoaded} />
            {isLoaded ? (
                <CustomMasonry key={key} items={filteredWorks.map(work => <Tile key={work.id} {...work} />)} />
            ) : (
                <p>Loading...</p>
            )}
            <WorksOnlyCheckbox masonryLoaded={masonryLoaded} selectedGenre={selectedGenre} onChange={handleGenreChange} />
        </div>
    );
}

export default Home;