import React, { useState, useEffect } from 'react';
import './ProfileModal.css';
import { FaInstagram, FaTwitch } from 'react-icons/fa';
import { FaSquareXTwitter } from "react-icons/fa6";

interface ProfileModalProps {
    artistName: string;
    profileImage: string;
    description: string;
    snsLinks: Array<{ icon: string, link: string }>;
    onClose: () => void;
}

const ProfileModal: React.FC<ProfileModalProps> = ({ artistName, profileImage, description, snsLinks, onClose }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
        return () => {
            setIsVisible(false);
        };
    }, []);

    useEffect(() => {
        if (!isVisible) {
            const timer = setTimeout(() => {
                onClose();
            }, 300);
            return () => clearTimeout(timer);
        }
    }, [isVisible, onClose]);

    const handleClose = () => {
        setIsVisible(false);
    };

    return (
        <div className={`profile-modal-overlay ${isVisible ? 'profile-modal-overlay-visible' : 'profile-modal-overlay-hidden'}`} onClick={handleClose}>
            <div className={`profile-modal-content ${isVisible ? 'profile-modal-content-visible' : 'profile-modal-content-hidden'}`} onClick={(e) => e.stopPropagation()}>
                <button className="profile-modal-close-btn" onClick={handleClose}>×</button>
                <img src={profileImage} alt={artistName} className="profile-image" />
                <h1>{artistName}</h1>
                <p>{description}</p>
                <div className="sns-links">
                    {snsLinks.map((link, index) => (
                        <a key={index} href={link.link} target="_blank" rel="noopener noreferrer">
                            {/* <img src={link.icon} alt={`Link to ${link.link}`} /> */}
                            {link.link === "https://x.com/usbhatyu" ? <FaSquareXTwitter /> : link.link === "https://www.instagram.com/usb_create/" ? <FaInstagram /> : <FaSquareXTwitter />}
                            {/* <FaInstagram /> */}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ProfileModal;
