import React from 'react';
import './About.css';

interface AboutProps {
  artistName: string;
  profileImage: string;
  description: string;
  snsLinks: { icon: string; link: string }[];
}

const About: React.FC<AboutProps> = ({ artistName, profileImage, description, snsLinks }) => {
  return (
    <div className="about-container">
      <h1 className="artist-name">{artistName}</h1>
      <img src={profileImage} alt="Artist Profile" className="profile-image" />
      <p className="description">{description}</p>
      <div className="sns-links">
        {snsLinks.map((sns, index) => (
          <a key={index} href={sns.link} target="_blank" rel="noopener noreferrer">
            <img src={sns.icon} alt="SNS Icon" className="sns-icon" />
          </a>
        ))}
      </div>
    </div>
  );
};

export default About;
