import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useShuffleText from '../utils/useShuffleText';

import './NameButton.css';

interface NameButtonProps {
    masonryLoaded: boolean;
}

const NameButton: React.FC<NameButtonProps> = ({ masonryLoaded }) => {

    const [startAnimation, setStartAnimation] = useState(false);

    useEffect(() => {
        if (masonryLoaded) {
            setStartAnimation(true);
        }
    }, [masonryLoaded]);

    const nameButtonRef = useShuffleText(startAnimation ? "MasatoUsuba / USB" : "");

    return (
        <nav className="namebutton">
            <button>
                <p ref={nameButtonRef} className='namebutton-text'></p></button>
        </nav>
    );
}

export default NameButton;
